import axios from 'axios';
import Swal from 'sweetalert2'

axios.interceptors.response.use((response) => {
    // Handle the response here
    return response
}, (error) => {
    // Handle errors here
    if(error?.response?.data?.data == 'Not authorized'){
        // TODO - MAKE THE ALERT WORKS
        // Swal.fire({
        //     icon: 'error',
        //     title: 'Tu sesión se ha caducado',
        //     showConfirmButton: true
        // });

        window.location.href = '/'
    }
    return Promise.reject(error)
})

export const generateAxiosInstance = (baseUrl = null) => {
    axios.defaults.headers.common['x-api-key'] = '9c6334a7-8215-46ff-8ec9-f442cdc43cac';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.baseURL = baseUrl;
    return axios;
};